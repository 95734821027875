import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Logic chip design: FPGAs`}</h4>
    <p>{`Field-programmable gate arrays ("FPGAs"), unlike other chips, can be reprogrammed after deployment to suit specific calculations, such as executing particular algorithms. U.S. firms capture virtually the entire FPGA design market.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      